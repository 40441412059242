// Convert 'MM-DD-YYYY' to 'Month Day, Year'
export const formatDate = (date) => {
	const date_array = date.split("-");
	let month = new Date()
	month.setMonth(date_array[1] - 1);
	month = month.toLocaleString([], {
    month: "long",
  })
	return `${month} ${date_array[2]}, ${date_array[0]}`;
}

// Get text excerpt
export const textExceprt = (text, num = 10) => {
	return text.split(" ").slice(0, num).join(" ");
}

// Get slug
export const getPostSlug = (date, uid) => {
	return `/blog/${date}/${uid}`
}

// Get first block of text from Prismic body content
export const getFirstTextBlock = (content) => {
	let first_text = null // variable for text excerpt

  /* loop through body content to find first text slice and set to first_text */
  content.forEach((el) => {
    if (el.primary?.text) {
      first_text = el.primary.text.text
      return false
    }
  })
	return first_text
}

